<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Pianificazione viaggio di arrivo
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="9">
        <v-expansion-panels v-model="infoPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
              Dati viaggio
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <TripInfoPanelFull :viaggio="viaggioTreno" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col>
        <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" :disabled="!isBtnUnloadingUtiEnabled" x-large
          @click="doRequestCosmosUnloadingUti()" :title="tLabel('Request Unloading Uti')">
          <v-icon large>mdi-update</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" :disabled="!isBtnUnloadingListEnabled" x-large
          @click="doRequestCosmosUnloadingList()" :title="tLabel('Request Unloading List')">
          <v-icon large>mdi-update</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn color="teal lighten-5" v-if="!isPianificato" @click="setStatoPianificato" title="Imposta stato Pianificato"
          :disabled="!isBtnPianificaEnabled" x-large>
          <v-icon large> mdi-calendar-check </v-icon>
        </v-btn>
        <v-btn color="success" v-else @click="setStatoPianificato" title="Annulla Pianificato"
          :disabled="!isBtnPianificaEnabled" x-large>
          <v-icon large> mdi-calendar-check </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="editPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
              ISTRUZIONI OPERATIVE
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-textarea v-model="viaggioTreno.istruzioniOperative" name="IstruzioniOperative"
                placeholder="Inserire eventuali Istruzioni operative" @input="saveIstruzioniOperativeDisabled = false"
                rows="2" />
              <div class="text-right">
                <v-btn color="success" @click="onSaveIstruzioniOperative" :disabled="saveIstruzioniOperativeDisabled">
                  Salva
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs color="warning" v-model="tab">
          <v-tab v-for="convoglio in convogli" :key="convoglio.id">{{ convoglio.nome }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="convoglio in convogli" :key="convoglio.id">
            <v-expansion-panels v-model="editPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">DATI
                  CONVOGLIO</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ConvoyEditPanel :convoglio="convoglio" :editingEnabled="editingEnabled(convoglio)" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">GESTIONE
                  CARRI</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <wagonOrdering :convoglio="convoglio" :editingEnabled="editingEnabled(convoglio)" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">GRAFICO
                  CONVOGLIO
                  <template>
                    <v-row no-gutters>
                      <v-col>
                        <v-btn color="primary" v-if="hasCosmosConnector() && !readonly" medium class="ma-2"
                          @click.native.stop="doRequestCosmosExportHandlingInExe()"
                          :title="tLabel('Request Handling in Exe')">
                          <v-icon medium>mdi-radio-handheld</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="convoglio" id="convoyVis" class="convoy-vis">
                    <div id="convoyCntr" class="convoy-cntr">
                      <convoySpatialVisualization :convoglio="convoglio" :lengthInterval="20" filterView="ARRIVO" />
                    </div>
                  </div>
                  <div v-else>Nessun convoglio associato al viaggio</div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">LISTA
                  ARRIVI</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ExportUtiList :convoglio="convoglio" :disabled="isPianificato" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import convoySpatialVisualization from "../../components/rails/convoy/ConvoySpatialVisualization";
import ConvoyEditPanel from "../../components/misc/ConvoyEditPanel";
import ExportUtiList from "../../components/rails/convoy/ExportUtiList";
import TripInfoPanelFull from '../../components/misc/TripInfoPanelFull';
import ToastMixin from "../../mixins/ToastMixin";
import railsMixins from '../../components/rails/mixins/railsMixins';
import wagonOrdering from '../../components/rails/convoy/WagonOrdering';

export default {
  name: "pianificazioneViaggioArrivo",
  components: {
    convoySpatialVisualization,
    ConvoyEditPanel,
    ExportUtiList,
    TripInfoPanelFull,
    wagonOrdering
  },
  mixins: [ToastMixin, railsMixins],
  filters: {
    formatStato: function (value) {
      return value ? "Pianificato" : "In pianificazione";
    },
    codice: function (value) {
      if (value) return value.codice;
    },
  },
  props: ["viaggioTrenoId"],
  data() {
    return {
      tab: null,
      tab2: null,
      viaggioTreno: {},
      infoPanel: [0],
      editPanel: [0, 1, 2, 3],
      legendPanel: [],
      wagonsPanel: [1],
      managementPanel: [0],
      convoyManagementEnabled: true,
      convogli: null,
      statoPianificato: false,
      isPianificato: false,
      saveIstruzioniOperativeDisabled: true,
      timeoutReload: null,
      readonly: false
    };
  },
  created() {
    this.$eventBus.$on("RailsEvent.VIAGGIO_MODIFICATO", async (data) => {
      console.log("EVENT ", data, "UPDATE?", data == this.viaggioTrenoId);
      if (data == this.viaggioTrenoId) {
        console.log("clearTimeout");
        clearTimeout(this.timeoutReload);
        console.log("after clearTimeout");
        await this.fetchData();
        console.log("after fetchData");
      }
    });
    this.$eventBus.$on("convoyReload", async () => {
      await this.fetchData();
    });

    this.$eventBus.$on('unloadingUtiReload', async () => {
      await this.fetchData();
    });
    this.$eventBus.$on('unloadingListReload', async () => {
      await this.fetchData();
    });

    this.$eventBus.$on('handlingInExeReload', async () => {
      await this.fetchData();
    });

  },
  async mounted() {
    await this.fetchData();
    const loggedUser = this.$store.getters['authentication/loggedUser'];
    this.readonly = loggedUser?.roles?.includes("EXT_TOS_USER");
  },
  computed: {
    isBtnPianificaEnabled() {
      var enabled = false;
      if (this.viaggioTreno && (
        this.viaggioTreno.statoOperativo === "CREATO" ||
        this.viaggioTreno.statoOperativo === "LISTAUTI" ||
        this.viaggioTreno.statoOperativo === "LISTAINPROGRESS" ||
        this.viaggioTreno.statoOperativo === "LISTAIMPORTATA" ||
        this.viaggioTreno.statoOperativo === "INLAVORAZIONE")) {
        enabled = true;
      }
      return enabled;
    },
    isBtnUnloadingUtiEnabled() {
      var enabled = false;
      if (this.viaggioTreno && (
        this.viaggioTreno.statoOperativo === "CREATO" ||
        this.viaggioTreno.statoOperativo === "LISTAUTI" ||
        this.viaggioTreno.statoOperativo === "LISTAINPROGRESS")) {
        enabled = true;
      }
      return enabled;
    },
    isBtnUnloadingListEnabled() {
      var enabled = false;
      if (this.viaggioTreno && (
        this.viaggioTreno.statoOperativo === "CREATO" ||
        this.viaggioTreno.statoOperativo === "LISTAUTI" ||
        this.viaggioTreno.statoOperativo === "LISTAINPROGRESS")) {
        enabled = true;
      }
      return enabled;
    },
  },
  methods: {
    async fetchData() {
      console.time("fetchData");
      try {
        this.viaggioTreno = await this.$api.railData.getViaggioTrenoById(
          this.viaggioTrenoId
        );
        console.timeEnd("fetchData");
        this.isPianificato = this.viaggioTreno.pianificato;
        console.time("fetchData convogli");
        this.convogli = await this.$api.trainData.findAllConvogliByCarroConvoglioViaggioTreno(
          this.viaggioTrenoId
        );
        console.timeEnd("fetchData convogli");
      } catch (e) {
        console.log(e);
      }
    },
    async onSaveIstruzioniOperative() {
      try {
        await this.$api.trainData.saveIstruzioniOperative(
          this.viaggioTreno.id,
          this.viaggioTreno.istruzioniOperative
        );
        this.saveIstruzioniOperativeDisabled = true;
        this.showSuccess("Istruzioni operative salvate correttamente");
      } catch (e) {
        console.log("ERRORE onSaveIstruzioniOperative", e);
        this.showError(
          "Salvataggio istruzioni operative non andato a buon fine"
        );
      }
    },
    async setStatoPianificato() {
      console.log("Call setStatoPianificato");
      const nuovoStato = this.isPianificato ? false : true;
      this.viaggioTreno = await this.$api.trainData.setViaggioTrenoPianificato(this.viaggioTreno.id, nuovoStato);
      this.isPianificato = this.viaggioTreno.pianificato;
      this.showSuccess("Stato modificato correttamente");
    },
    markModified: function (visitaUti) {
      visitaUti.modified = true;
      this.saveDisabled = false;
      console.log("Modifica uti", visitaUti.id);
    },
    async doRequestCosmosUnloadingUti() {
      await this.$api.trainData.doRequestCosmosUnloadingUti(this.viaggioTreno.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Unloading Uti";
      this.showSuccess(msg);
      this.timeoutReload = setTimeout(() => { this.fetchData() }, 7000);
      this.$eventBus.$emit('unloadingUtiReload');
    },
    async doRequestCosmosUnloadingList() {
      await this.$api.trainData.doRequestCosmosUnloadingList(this.viaggioTreno.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Unloading List";
      this.showSuccess(msg);
      this.timeoutReload = setTimeout(() => { this.fetchData() }, 7000);
      this.$eventBus.$emit('unloadingListReload');
    },
    async doRequestCosmosExportHandlingInExe() {
      await this.$api.trainData.doRequestCosmosExportHandlingInExe(this.viaggioTreno.codiceEsterno);
      const msg = "Invio richiesta aggiornamento Handling in Exe";
      this.showSuccess(msg);
      this.timeoutReload = setTimeout(() => { this.fetchData() }, 7000);
      this.$eventBus.$emit('handlingInExeReload');
    },
    editingEnabled(convoglio) {
      return convoglio.stato
        && (convoglio.stato == 'CREATO' || convoglio.stato == 'INTERMINAL');
    },
    parkRailsEditingEnabled(convoglio) {
      return convoglio.stato && convoglio.stato.toLowerCase() === 'creato';
    },
  },
};
</script>
<style scoped>
.convoy-vis {
  position: relative;
  overflow-x: scroll;
  cursor: pointer;
}
</style>
